<template>
  <div>
    <v-data-table item-key="id" multi-sort :headers="firmenHeaders" :items="filteredFirmen" :expanded.sync="expanded"
      single-expand>
      <template v-slot:item="props">
        <tr @click="setFirma(props.item.id)" :class="{'black text-white': props.isExpanded}">
          <td class="bold">{{ props.index}}</td>
          <td class="bold">{{ props.item.name }}</td>
          <td class="bold">{{ props.item.straße }}</td>
          <td class="bold">{{ props.item.ort }}</td>
          <td class="bold">{{ props.item.plz }}</td>
          <td class="bold">{{ props.item.land.name }}</td>
          <td class="bold">{{ props.item.telefon }}</td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <transition name="slide-y-transition">
          <td :colspan="headers.length" v-if="firma">
            <v-card flat class="grey lighten-3 pa-2">
              <div class="text-right">
                <v-btn @click="updateFirma()" color="accent" dark>Speichern</v-btn>
                <v-btn @click="deleteFirma()" color="secondary" class="ml-2" outlined dark>Löschen</v-btn>
              </div>
              <v-row>
                <v-col md="2">
                  <v-text-field label="Name" color="accent" v-model="firma.name" @change="changeDirtyFirma" filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-text-field label="Straße" color="accent" v-model="firma.straße" @change="changeDirtyFirma" filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-text-field label="Plz" color="accent" @change="changeDirtyFirma" v-model="firma.plz" filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-text-field label="Ort" color="accent" @change="changeDirtyFirma" v-model="firma.ort" filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-text-field v-model="firma.additionalAddressInformation" color="black" label="Zusatz Adresse"
                    filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-autocomplete v-model="firma.land" :items="laender" item-text="name" item-value="id" return-object
                    color="accent" dense class="black--text" label="Land" filled>
                  </v-autocomplete>
                </v-col>
                <v-col md="2">
                  <v-text-field label="Telefon" @change="changeDirtyFirma" v-model="firma.telefon" filled>
                  </v-text-field>
                </v-col>
                <v-col md="2">
                  <v-text-field label="Bemerkung" @change="changeDirtyFirma" v-model="firma.bemerkung" filled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </td>
        </transition>
      </template>
    </v-data-table>
  </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';


    export default {
        data() {
            return {
                personen: [],
                selectsItems: { anrede: ['Herr', 'Frau', 'K/A'], kategorie: [], land: [], aktion: [], name: [], quelle: [] },
                test: [],
                filter: { vorname: '', nachname: '', firma: '', email: '', kategorie: '', ort: '' },
                id: null,
                dirtyFirma: false,
                dirtyFirmaSelect: false,
                dirtyFilter: false,
                firma: null,
                tabfirma: null,
                firmaCopy: null,
                tempfirmaID: null,
                expanded:[],

                firmenHeaders: [
                    {
                        text: 'Index',
                        align: 'left',
                        sortable: true,
                        value: 0
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Straße', value: 'straße' },
                    { text: 'Ort', value: 'ort' },
                    { text: 'PLZ', value: 'plz' },
                    { text: 'Land', value: 'land.name' },
                    { text: 'Telefon', value: 'telefon' },],
            }
        },
        watch: {

        },
        beforeMount() {
        },
        mounted() {

        },

         computed: {
            ...mapState(['firmen', 'filteredFirmen'])

        },
        props: ['laender', 'aktionen', 'kategorien'],
        methods: {
          clearExpanded(){
            this.expanded = [];
          },
            changeDirtyFirma: function () {
                if (!this.dirtyFirma) {
                    this.dirtyFirma = true
                }
            },
            restoreFirma: function () {
                if (this.firmaCopy == null) {
                    this.firma = null;
                    this.newFirma = null;

                }
                for (var prop in this.firma) {
                    this.firma[prop] = this.firmaCopy[prop];
                }
                this.dirtyFirma = false;
            },
            setFirma: function (id) {
                var tempfirma = this.firmen.filter(function (element) { return element.id == id })[0];

                this.firma = JSON.parse(JSON.stringify(tempfirma));
                this.firmaCopy = JSON.parse(JSON.stringify(tempfirma));
                this.expand(this.firma);
            },
            updateFirma: function () {
                this.$http.post('/api/Stammdaten/updateFirma', this.firma).then(response => {
                  this.clearExpanded();
                  this.$store.dispatch('updateFirma', this.firma);
                }).catch((error) => console.log(error));
            },
          async deleteFirma() {
            await this.$http.get('/api/Stammdaten/SoftDeleteFirma/' + this.firma.id);
            await this.$store.dispatch('deleteFirma', this.firma.id);
            await this.$store.dispatch('addFirmaPapierkorb',  this.firma);
            this.$emit('filter');
          },
          expand(value){
            const item = this.expanded.find(i => i.id === value.id);
            const index = this.expanded.indexOf(item);

            if(index > -1){
              this.expanded.splice(index, 1);
            }
            else{
              this.expanded = [];
              this.expanded.push(value);
            }
          },
            loadNewFirma: function () {
                var id = this.firma.id;
                var newFirma = this.firmen.filter(function (element) { return element.id == id })[0];

                this.firma.straße = newFirma.straße;
                this.firma.plz = newFirma.plz;
                this.firma.ort = newFirma.ort;
                this.firma.land = newFirma.land;
                this.firma.kategorie = newFirma.kategorie;
                this.firma.telefon = newFirma.telefon;
                this.firma.bemerkung = newFirma.bemerkung;

                if (!this.dirtyFirma) {
                    this.dirtyFirma = true

                }
            },
            updateEntry: function (index) {
                this.$http.post('/api/Stammdaten/updateEntry', this.person).then(response => {
                }).catch((error) => console.log(error));
            },
        },

    }
</script>

<style scoped>
.bold {
  font-weight: 700;
}
</style>
